<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('INVOICE.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('INVOICE.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
  </vs-row>

  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div id="invoices_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="invoices"
                :noDataText="$t('INVOICE.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('INVOICE.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="invoice.bez">
                    {{$t('INVOICE.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="invoice.invoice_nr">
                    {{$t('INVOICE.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="tr.debitorName">
                    {{$t('INVOICE.TABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="tr.invoice.type">
                    {{$t('INVOICE.TABLE.COL7')}}
                  </vs-th>    
                  <vs-th sort-key="tr.invoice.last_change">
                    {{$t('INVOICE.TABLE.COL4')}}
                  </vs-th> 
                  <vs-th sort-key="tr.invoice.state">
                    {{$t('INVOICE.TABLE.COL5')}}
                  </vs-th>  
                  <vs-th sort-key="tr.invoice.created_by_str">
                    {{$t('INVOICE.TABLE.COL6')}}
                  </vs-th> 
                  <vs-th sort-key="tr.invoice.betrag">
                    {{$t('INVOICE.TABLE.COL8')}}
                  </vs-th>                                                                                                                                                                                                             
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                   
                    <vs-td :data="tr.invoice.bez">
                      {{tr.invoice.bez}}
                    </vs-td>
                    <vs-td :data="tr.invoice.invoice_nr">
                      {{tr.invoice.invoice_nr}}
                    </vs-td>    
                    <vs-td :data="tr.debitorName">
                      {{tr.debitorName}}
                    </vs-td> 
                    <vs-td :data="tr.invoice.type">
                      {{GetInvoiceTypeText(tr.invoice.type)}}
                    </vs-td>                                           
                    <vs-td :data="tr.invoice.last_change">
                      {{DateToString(tr.invoice.last_change,false)}}
                    </vs-td>                   
                    <vs-td :data="tr.invoice.state">
                      <vs-chip :color="GetCommonStateColor(tr.invoice.state)">
                        {{GetCommonStateText(tr.invoice.state)}}</vs-chip>
                        <MailSentChip :key="'chip'+tr.invoice.id" v-if="IsNotNull(tr.invoice.mailSentInfo)" :MailSentInfo="tr.invoice.mailSentInfo"></MailSentChip>
                    </vs-td>  
                    <vs-td :data="tr.invoice.created_by_str">
                      {{tr.invoice.created_by_str}}
                    </vs-td> 
                    <vs-td >
                      {{GetInvoiceSumOffen(tr.invoice)}} / <h4>{{GetInvoiceSum(tr.invoice)}}</h4>
                    </vs-td>                                                                                                                
                    <vs-td>
                      <PollingButtonDoc  :key="'polling'+tr.invoice.id" :DocumentType="docType" :InitDocId="tr.invoice.document_fk" :LinkedFk="tr.invoice.id" Icon="get_app" Size="small" Color="primary" Type="filled" ButtonClass="mr-1" ref="InvoicePollingBtn" v-on:clicked="DownloadInvoice"></PollingButtonDoc>
                      <vs-button  @click="NavigateTo(tr.order_fk)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>
import enumHelper from '../helper/enumHelper';
import {documentType} from "../helper/enumHelper";
import PollingButtonDoc from "../views/components/PollingButtonDoc.vue"
import codeHelper from '../helper/staticCodeHelper';
import helperMethods from '../helper/staticFuncHelper';

export default {
  name: "InvoicesOverview",
  components: {
    PollingButtonDoc
  },
    data: function (){
      return {
      invoices:[],
      docType:documentType.INVOICE,
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.accounting.invoiceOverview.data != null)
      {
        data = this.$store.state.accounting.invoiceOverview.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.invoiceOverview.status) != 'undefined')
        loading = this.$store.state.accounting.invoiceOverview.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
          this.invoices = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#invoices_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#invoices_table'});
        }
      },
    },
  methods: { 
      ...enumHelper, 
      ...codeHelper,
      ...helperMethods,  
      LoadData: function ()
      {
          this.$store.dispatch('accounting/getInvoiceOverview', { root: true },{ root: true }); 
      },
      DownloadInvoice(actDocId)
      {
        if(this.IsNotNull(actDocId))
        {
            this.$store.dispatch('document/downloadDocument', actDocId)
                .then(response => {
                    this.DownloadWithFileSaver(response);
                  }
                );
        }
      },     
     GetInvoiceSum(invoice)
    {
      var sum = 0;
      var txt = "";
      if(this.IsNotNull(invoice))
      {
        sum = invoice.amount;
        txt = this.GetCurrencyString(sum,invoice.language_fk,invoice.currency_kuerzel);
      }
      return txt;
    },
    GetInvoiceSumOffen(invoice)
    {
      var txt = "";
      if(this.IsNotNull(invoice))
      {
        txt = this.GetCurrencyString(invoice.amount_bez,invoice.language_fk,invoice.currency_kuerzel);
      }
      return txt;
    },
    GetCurrencyString(amount,language_fk,currency_kuerzel){
        return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
      },  
      NavigateTo(id)
      {
        this.$router.push({ name: 'Order', params: { order_fk: id } });
      }   
  }
};

</script>